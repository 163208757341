<template>
    <div class="px-10 w-3/6">
        <div class="flex gap-5 py-10">
            <div class="w-1/2 gap-5 flex">
                <input
                    type="text"
                    class="w-full px-5 py-2 rounded border"
                    placeholder="Enter keyword"
                    v-model="keyword"
                >
                <button
                    type="button"
                    class="px-5 py-3 bg-peach text-white rounded"
                    @click="fetchUsers"
                >
                    Search
                </button>
            </div>
        </div>

        <div class="bg-peach flex font-medium items-center p-5 rounded-t-lg text-white">
            <div class="w-1/3">Name</div>
            <div class="w-1/3 text-center">Codes</div>
            <div class="w-1/3 text-center">Status</div>
        </div>

        <template v-if="sellers.total !== 0">
            <div
                v-for="(seller, index) in sellers.data"
                class="flex gap-5 items-center p-5 text-sm"
                :class="{ 'bg-white' : !(index % 2), 'bg-gray-100' : index % 2, 'rounded-b-lg' : index == sellers.data.length - 1 }"
                :key="seller.id"
            >
                <div class="font-bold text-peach w-1/3 tracking-widest">{{ seller.company.name }}</div>
                <div class="w-1/3">
                    <button
                        v-if="seller"
                        class="py-2 rounded text-sm text-white w-full"
                        :class="{ 'bg-red-600' : seller.can_generate, 'bg-green-600' : !seller.can_generate }"
                        @click="toggleStatus(seller.id, seller.can_generate, 'codes')"
                    >
                        {{ seller.can_generate ? 'Deactivate' : 'Activate' }}
                    </button>
                </div>
                <div class="w-1/3">
                    <button
                        v-if="seller"
                        class="py-2 rounded text-sm text-white w-full"
                        :class="{ 'bg-red-600' : seller.is_partner, 'bg-green-600' : !seller.is_partner }"
                        @click="toggleStatus(seller.id, seller.is_partner, 'partner')"
                    >
                        {{ seller.is_partner ? 'Deactivate' : 'Activate' }}
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="text-center text-2xl mt-3">
                No data found.
            </div>
        </template>

        <template v-if="sellers.total !== 0">
            <TailwindPagination
                :data="sellers"
                @pagination-change-page="fetchUsers"
            />
        </template>
    </div>
</template>

<script>
    import TailwindPagination from 'laravel-vue-pagination'

    export default {
        components: {
            TailwindPagination
        },
        data() {
            return {
                sellers: [],
                limit: 10,
                page: 1,
                keyword: ''
            }
        },
        computed: {
            AUTH_USER() {
                return this.$store.getters.AUTH_USER;
            },
        },
        mounted() {
            this.fetchUsers()
        },
        methods: {
            async fetchUsers(page = undefined) {
                try {
                    await this.$store.commit('SET_CODES', [])
                    await this.$store.commit('SET_IS_LOADING', { status: 'open' })

                    const { sellers } = await this.$store.dispatch('FETCH_PARTNERS', {
                        pagination: this.limit,
                        page: page ?? this.page,
                        keyword: this.keyword
                    })

                    this.sellers = sellers

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            },
            async toggleStatus(id, status, type) {
                try {

                    const data = await this.$store.dispatch(
                        'TOGGLE_PARTNER_STATUS',
                        {
                            id: id,
                            status: status ? 'inactive' : 'active',
                            type: type
                        }
                    )

                    this.page = 1
                    this.keyword = ''
                    this.fetchUsers()

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    console.log('error', error)

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            }
        }
    }
</script>

<style>
    ul.pagination {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px; 
    }
</style>