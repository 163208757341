<template>
    <MainLayout title="Partners">
        <template #content>
            <SellerList />
        </template>
    </MainLayout>
</template>

<script>
import MainLayout from '_layouts'
import SellerList from '_components/Tourism/SellerList'

export default {
    components: {
        MainLayout,
        SellerList,
    }
}
</script>